import { render, staticRenderFns } from "./LgameLogin.vue?vue&type=template&id=36afaacb&scoped=true&"
import script from "./LgameLogin.vue?vue&type=script&lang=js&"
export * from "./LgameLogin.vue?vue&type=script&lang=js&"
import style0 from "./LgameLogin.vue?vue&type=style&index=0&id=36afaacb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36afaacb",
  null
  
)

export default component.exports