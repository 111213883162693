<template>
    <div :style="{ backgroundColor: '#020202'}">
        <script type="application/javascript" defer src="https://player.twitch.tv/js/embed/v1.js" ref="twitchScript"></script>


        <!-- Lgame NavBar -->
        <LgameNavBar></LgameNavBar>
        <div class="main-page-wrapper">

            <div class="main-page-landing-area d-flex justify-content-start justify-content-lg-center align-items-center align-items-lg-center position-relative"
                :style="{
                   backgroundImage: 'url(' + require('@/assets/desktop-background.png') + ')',
                }"
            >   
                <div class="landing-content  w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center w-100 container-fluid mt-4 mt-lg-0 m-lg-0 px-0">
                    <div class="about-container text-center w-lg-50">
                        <div class="logo-container d-flex flex-column justify-content-center align-items-center">
                            <div class="my-4">
                                <img class="eblue-cup" :src="require('@/assets/eblue-cup.svg')" width="180px" />
                            </div>
                            <h2 class="main-title m-0">
                                Eblue world cup<br>
                                tournament
                            </h2>
                            <div class="my-4">
                                <img :src="require('@/assets/fifa.png')" width="280px"/>
                            </div>
                            <div class="participate-container w-100">
                                <LgameParticipateCard
                                    @init-pop-up="initPopUp"
                                    @view-bracket="viewBracket"
                                ></LgameParticipateCard>
                            </div>
                            <div class="sponsors-container d-flex justify-content-between align-items-center w-100 my-5">
                                    <div class="sponsor-item">
                                        <img :src="require('@/assets/mt-cash.png')" height="38px"/>
                                    </div>
                                    <div class="sponsor-item">
                                        <img :src="require('@/assets/lenovo.png')"  height="38px"/>
                                    </div>
                                    <div class="sponsor-item">
                                        <img :src="require('@/assets/eblue.png')" height="38px" />
                                    </div>
                                    <div class="sponsor-item">
                                        <img :src="require('@/assets/geekmaroc.png')" height="38px" />
                                    </div>
                                    <div class="sponsor-item">
                                        <img :src="require('@/assets/lgame.png')" height="38px" />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="concept-container container p-lg-0 my-3 my-lg-5 py-5 w-100">
                <div class="description-container my-5 p-0 py-5">
                    <h2 class="secondary-title text-left mb-3">A propos du tournoi:</h2>
                    <p class="description-paragraph text-justif mr-lg-5 mb-5">
                        À l'occasion de la coupe du monde Qatar 2022, GEEK Maroc organise la Eblue World Cup qui est un tournoi sur le jeu FIFA 23
                        Les qualifications du tournoi seront jouées sur trois phases :
                    </p>
                    <div class="phases-container d-flex flex-column flex-md-row justify-content-center align-items-start mt-5 w-100">
                        <div class="phase-item w-50">
                            <h3 class="phase-title text-left">PHASE QUALIFICATIVE<br>
                                - <span class="phase-high-light">EN LIGNE</span>
                            </h3>
                            <p class="phase-desc text-justify pr-0 pr-md-5 mt-4">
                                On vous
                                donne RDV le 08 et 09 décembre pour jouer aux qualifications en ligne du tournoi et tenter votre chance pour se  qualifier à la Grande Finale le 17 décembre 2022
                                au sein du centre Eblue à 14h.
                            </p>
                            <div class="online-participate-container btn-container d-flex align-items-center justify-content-center px-2 py-2 mb-5 mb-md-0 w-75" v-if="registrationStatus">
                                <button class="btn-participate p-0"
                                     data-toggle="modal" data-target="#loginModal"
                                    @click="participateAction(false)"
                                    v-if="!isAuthenticated"
                                >participer
                                    <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px"/>
                                </button>
                                <button class="btn-participate p-0"  data-toggle="modal" data-target="#lgame-participation-pop-up" @click="participateAction(false)"
                                    v-else>
                                    participer
                                    <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                                </button>
                            </div>
                        </div>
                        <div class="phase-item w-50">
                            <h3 class="phase-title text-left">PHASE QUALIFICATIVE<br>
                               - <span class="phase-high-light">PHYSIQUE</span>
                            </h3>
                            <p class="phase-desc text-justify mt-4 pr-md-5">
                                Inscrivez-vous dès maintenant aux qualifications LAN du tournoi qui se dérouleront aux centre Eblue à Casablanca le 10 - 11 décembre  et tenter votre chance pour se qualifier à la Grande Finale le 17 décembre 2022.
                            </p>
                            <div class="offline-participate-container btn-container d-flex align-items-center justify-content-center px-2 py-2 w-75" v-if="LAN_registrationStatus">
                                <button class="btn-participate p-0"
                                    data-toggle="modal" data-target="#loginModal"
                                    @click="participateAction(true)"
                                    v-if="!isAuthenticated"
                                >
                                participer
                                    <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                                </button>
                                <button class="btn-participate p-0" data-toggle="modal" data-target="#lgame-participation-pop-up"  @click="participateAction(true)"
                                    v-else>
                                    participer
                                    <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="phase-item mt-5 w-50">
                        <h3 class="phase-title text-left">Grande Finale<br>
                            - <span class="phase-high-light">Eblue</span>
                        </h3>
                        <p class="phase-desc text-justify pr-0 pr-md-5">
                            Quart, demi et la finale du tournoi au sein du centre le 17 décembre
                            à 14h avec une soirée de remise des prix.
                        </p>
                    </div>
                </div>
            </div>
            <div class="prize-container container p-lg-0 my-3 my-lg-5 py-5 w-100">
                <h2 class="secondary-title text-left">Prix:</h2>
                <div class="first-place d-flex justify-content-start align-items-start">
                    <div>
                        <img :src="require('@/assets/gold-trophy.png')" />
                    </div>
                    <div class="prize-desc-container ml-2">
                        <h3 class="prize-title text-left">Première place<br>
                        -
                        </h3>
                        <p class="prize-desc text-justify">
                            PC Lenovo Legion 5 | Intel Core I7-10750H | Nvidia Geforce RTX 2060 6GB GDDR6 | 16 Go RAM (d'une valeur de 18 000dh) + Maillot de l'équipe nationale marocaine
                        </p>
                    </div>
                </div>
                <div class="second-place d-flex justify-content-start align-items-start my-2">
                    <div>
                        <img :src="require('@/assets/silver-trophy.png')" />
                    </div>
                    <div class="prize-desc-container ml-2">
                        <h3 class="prize-title text-left">Deuxième place<br>
                        -
                        </h3>
                        <p class="prize-desc text-justify">
                            Ecran Lenovo Legion Y25g-30 (d'une valeur de 11 000dh) + Maillot de l'équipe nationale marocaine.
                        </p>
                    </div>
                </div>
                <div class="third-place d-flex justify-content-start align-items-start">
                    <div>
                        <img :src="require('@/assets/bronze-trophy.png')" />
                    </div>
                    <div class="prize-desc-container ml-2">
                        <h3 class="prize-title text-left">Troisième place<br>
                            -
                        </h3>
                        <p class="prize-desc text-justify">
                            Casque gaming Lenovo Legion H300 + Souris gaming Lenovo Legion M300 + Abonnement Eblue de 1500DH.
                        </p>
                    </div>
                </div>
            </div>
        
            <div class="stream-container container my-3 my-lg-5 p-lg-0 py-5 w-100" id="stream">
                <h2 class="secondary-title text-left mt-5">Stream du tournoi: </h2>
                <div class="align-self-center stream" ref="twitchPlayer"></div>
            </div>
            <div class="bracket-container container my-3 my-lg-5 p-lg-0 py-4 w-100" id="bracket">
                <h2 class="secondary-title text-left">Bracket</h2>
                <div class="bracket d-flex justify-content-center align-items-center">
                    <BracketViewer></BracketViewer>
                </div>
            </div> 
        </div>
        <div class="sponsor-credits my-5 p-5 w-100">
            <h2 class="secondary-title text-white">Tournoi sponsorisé par</h2>
            <div class="my-5 w-100">
                <div class="sponsors-container container d-flex justify-content-center align-items-center  p-3 w-100">
                    <div class="mx-5">
                        <img class="credit-item" :src="require('@/assets/mt-cash-color.png')" width="160px"/>
                    </div>
                    <div class="mx-5">
                        <img class="credit-item" :src="require('@/assets/lenovo-color.png')"  width="160px"/>
                    </div>
                </div>
            </div>
           
        </div>
        <!-- lgame footer -->
        <LgameFooter></LgameFooter>

        <!-- Lgame participate pop up-->
        <LgameParticipatePopUp
            :pop-up-type="popUpOptions.type"
            :title="popUpOptions.title"
            :pop-up-message-body="popUpOptions.message"
            :with-options="popUpOptions.withOptions"
            :options="popUpOptions.options"
            v-if="showPopUp"
            @modal-closed="abortAction"
            @acceptAction="acceptAction"
            @abortAction="abortAction"
        ></LgameParticipatePopUp>

    </div>
</template>

<script>
/* eslint-disable */
    import BracketViewer from './BracketViewer.vue';
    import LgameFooter from './LgameFooter.vue';
    import LgameNavBar from './NavBar.vue';
    import LgameParticipatePopUp from './LgameParticipatePopUp.vue';
    import LgameParticipateCard from './ParticipateCard';

    export default{
        name: "MainPage",
        components:{
            BracketViewer,
            LgameParticipatePopUp,
            LgameNavBar,
            LgameFooter,
            LgameParticipateCard,
        },
        data(){
            return{
                twitchPlayer: null,
                playerLoaded: false,

                showPopUp: false,
                popUpOptions: {
                    type: '',
                    title: '',
                    message: '',
                },
                OFFLINE_TOURNAMENT_ID: 118,
                ONLINE_TOURNAMENT_ID: 116,
                hasCustomFields: true,
                TYPE_PLAYER: 1,
                TYPE_TEAM: 2,
                participantType: 1,
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.currentTournament;
                },
                set(val) {
                    return val;
                }
            },
            currentTournament: {
                get() {
                    return this.$store.state.currentTournament.tournamentItem ? this.$store.state.currentTournament.tournamentItem : null;
                }
            },
            isAuthenticated: {
                get() {
                    return this.$store.state.currentUser.isAuthenticated;
                }
            },
            registrationStatus: {
                get() {
                    if (this.$store.state.currentTournament.tournamentItem?.settings){
                        return this.$store.state.currentTournament.tournamentItem.settings.registration_status;
                    }
                    
                },
                set(val) {
                    return val;
                }
            },
            LAN_registrationStatus: {
                get() {
                    if (this.$store.state.currentTournament.LAN_tournamentItem?.settings){
                        return this.$store.state.currentTournament.LAN_tournamentItem.settings.registration_status;
                    }
                },
                set(val) {
                    return val;
                }
            }
        },
    /* eslint-disable */
        watch:{
            registationStatus(newVal, oldVal) {
                return newVal;
            },
            LAN_registrationStatus(newVal, oldVal) {
                return newVal;
            }
        },
        methods: {
            initPlayer(){
                // eslint-disable-next-line
                this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                    width: '100%',
                    height: '100%',
                    channel: 'geekmaroctv',
                });
                this.twitchPlayer.setVolume(0.5);
                this.playerLoaded = true
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                   
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: true,
                    })*/
                });
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {
                    
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: false,
                    })*/
                });   
            },
            acceptAction(payload) {
                this.showPopUp = false;
                
                if (payload && payload.hasCustomFields) {
                    if (payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId,
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        this.$store.dispatch(this.popUpOptions.popUpDispatch, {
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                } else {
                    if (payload && payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        console.log('payload from pop up action ================= ')
                        console.log(payload)
                        console.log('payload from pop up action ================= ')
                        if (this.popUpOptions.dispatchData) {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        } else {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                                this.$store.dispatch('currentTournament/triggerLoading', false);
                            });
                        }
                    }
                }
            },
            abortAction() {
                this.showPopUp = false;
                this.$store.dispatch('currentTournament/triggerLoading', false);
            },
            initPopUp(options) {
                console.log(options);
                this.popUpOptions = options;
                this.showPopUp = true;
            },
             viewBracket(){
                this.$refs.bracketTrigger.click();
            },
            participateAction(isLAN = false) {
                // participate current auth user
                if (this.isAuthenticated) {
                    this.$store.dispatch('currentTournament/triggerLoading', true);
                    if (this.participantType === this.TYPE_TEAM) {
                        this.$store.dispatch('currentTournament/getCurrentPlayerTeams');
                        this.initPopUp({
                            type: 'teams',
                            title: "Choisir une équipe",
                            message: "Choisir une équipe parmis vous equipes pour participer a cette tournoi:",
                            withOptions: false,
                            popUpDispatch: 'currentTournament/setParticipantTeam',
                        });
                        
                    }
                    if (this.participantType === this.TYPE_PLAYER) {
                        if (this.hasCustomFields) {
                            this.initPopUp({
                                type: 'custom',
                                withOptions: true,
                                title: "Formulaire d'inscription",
                                message: 'Merci de remplire les champs suivants pour participer au tournoi',
                                options: {
                                    mainAction: 'Participer',
                                    secondAction: 'Annuler'
                                },
                                popUpDispatch: isLAN ? 'currentTournament/LAN_setParticipant' : 'currentTournament/setParticipant',
                            });
                        } else {
                            if (isLAN) {
                                this.$store.dispatch('currentTournament/LAN_setParticipant')
                                    .then(() => {
                                        this.$store.dispatch('currentTournament/LAN_triggerLoading', false);
                                    });
                            } else {
                                this.$store.dispatch('currentTournament/setParticipant')
                                    .then(() => {
                                        this.$store.dispatch('currentTournament/triggerLoading', false);
                                    });
                            }

                        }
                    }
                } else {
                    this.$root.$emit('show-login', 'check_participation');
                }

            },
        },
        created(){
            this.$store.dispatch('currentUser/authCheck').then(response => {
                if (response.success) {
                    this.$store.dispatch('currentUser/setCurrentUser');
                }
            });
           this.$store.dispatch('currentTournament/getTournament', this.ONLINE_TOURNAMENT_ID);
           this.$store.dispatch('currentTournament/LAN_getTournament', this.OFFLINE_TOURNAMENT_ID);
        },
        mounted() {
            const resizeOps = () => {
                document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
            };

            resizeOps();
            window.addEventListener("resize", resizeOps);
            
            this.$refs['twitchScript'].onload = () => {
                this.initPlayer();
            }
        }
    }

</script>

<style scoped>
.main-page-landing-area{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
}
.yellow-banner{
    bottom: 0;
    left:0;
}
.icons-top-right{
    right: 10px;
    top: 10px;
}
.icons-bottom-left{
    left: 10px;
    bottom: 70px;
}
.main-title{
    font-family: 'chaneyultra_extended', sans-serif;
    text-transform:uppercase;
    font-size:36pt;
    letter-spacing: 1px;
}

.secondary-title{
    color:#058DF8;
    font-family: 'chaneyultra_extended', sans-serif;
    text-transform: uppercase;
    font-size: 26pt;
}
.sponsor-credits .secondary-title{
    font-size: 20pt;
}
.phase-title{
    color: white;
    text-transform: uppercase;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 24pt;
    line-height: 100%;
}
.phase-high-light{
    color:#058DF8;
}
.phase-desc{
    color: white;
    font-family: 'monument_extendedregular', sans-serif;
    font-size: 14pt;
}
.btn-container {
    border: 2px solid white;
    height: 46px;
}
.btn-container:hover{
    background-color: #058ff8ab;
    cursor: pointer;
    transform: translateX(2px)
}
.btn-participate {
    font-family: 'chaneyultra_extended', sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    color: white;
    border: none;
    outline: none;
    background-color: transparent;
}

.prize-title{
    color: white;
    text-transform: uppercase;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 20pt;
    line-height: 100%;
}
.prize-desc{
    color: white;
    font-family: 'monument_extendedregular', sans-serif;
    font-size: 12pt;
}
.about-container p{
    font-family: 'Gemunu Libre Regular';
    font-size: 18pt;
}
.concept-container .main-title{
    color:#E30613;
    font-size: 36pt;
    letter-spacing: unset;
}
.description-paragraph{
    color: white;
    font-family: 'monument_extendedregular', sans-serif;
    font-size: 14pt;
    text-align: justify;
}


.stream{
    height: 600px;
    background-color:transparent;
    border: 2px solid white;
}
.bracket{
    min-height: 600px;
    background-color: transparent;
    border:2px solid white;
    overflow: hidden;
}
.btn-tab{
    border: unset;
    background: transparent;
    outline: none;
    color: white;
    background-size: 100% 100%;
    height: 42px;
    min-width: 280px;
    font-family: 'Gemunu Libre Bold';
    font-size: 16pt;
    text-decoration: unset;
    line-height: 42px;
}


@media screen and (max-width: 800px){
    .main-page-landing-area{
        background-size: cover;
    }
}

@media screen and (max-width: 768px) {
    .eblue-cup{
        width: 50vw;
        height: unset;
    }
    .main-title{
        font-size: 16pt;
    }

    .sponsors-container{
        width: 75% !important;
    }
    .sponsor-item img{
        height: 14px;
    }
    .about-container p{
        font-size: 0.9rem;
        padding-inline: 1rem;
    }
   .phase-item{
        width: unset !important;
   }
    .concept-container	.main-title{
        font-size: 24pt;
    }
    .description-container .marina-img{
       width: 90vw !important;
        aspect-ratio: 16 / 9;
        height: auto;
    }
    .description-paragraph{
        font-size: 16pt;
    }
    .activity-item{
        width: 100% !important;
    }
    .section-title{
        font-size: 36pt;
    }
    .stream{
        width: 100% !important;
        height: 360px;
    }
    .sponsor-credits .secondary-title {
        font-size: 12pt;
    }
    .credit-item{
        width: 100px;
    }


}
</style>