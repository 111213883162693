<template>
    <div class="participate-card-wrapper" v-if="!tournamentStarted">
        <div class="participate-card-container d-flex flex-column justify-content-md-center align-items-center">
            <div class="card-title-container px-4 py-2">
                <h3 class="card-title m-0">Participer au tournoi</h3>
            </div>
            <div class="btns-participate-wrapper d-flex flex-column flex-md-row justify-content-center aligm-items-center w-100">
                <div class="offline-participate-container btn-container d-flex align-items-cnter justify-content-center py-2 my-2 my-md-0 w-100">
                    <div class="" v-if="!LAN_registrationStatus">
                        <button class="btn-participate p-0">
                            INSCRIPTIONS FERMées
                            <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                        </button>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center align-items-center" v-if="!isAuthenticated">
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#loginModal" @click="participateAction(true)">
                                Qualification en LAN
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" v-else>
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#lgame-participation-pop-up"
                                @click="participateAction(true)" v-if="!isLAN_participant">
                                Qualification en LAN
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#lgame-participation-pop-up"
                                @click="cancelParticipateAction(true)" v-else>
                                se desinscrire (LAN)
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                        </div>
                    </div>
                    
                </div>
                <div class="online-participate-container btn-container d-flex align-items-cnter justify-content-center py-2 w-100">
                    <div class="" v-if="!registrationStatus">
                        <button class="btn-participate p-0">
                            INSCRIPTIONS FERMées
                            <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                        </button>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center align-items-center" v-if="!isAuthenticated">
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#loginModal"
                                @click="participateAction(false)">Qualification en ligne
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" v-else>
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#lgame-participation-pop-up"
                                @click="participateAction(false)" v-if="!isParticipant">
                                Qualification en ligne
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                            <button class="btn-participate p-0" data-toggle="modal" data-target="#lgame-participation-pop-up"
                                @click="cancelParticipateAction(false)" v-else>
                                se desinscrire (online)
                                <img class="ml-4" :src="require('@/assets/arrow-next.png')" width="18px" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="registration-status d-flex justify-content-between align-items-center my-2 w-100"
            >
                <div class="lan-status">
                    <h2 class="status-count text-left m-0">
                        {{ LAN_participantCount > 0 ? LAN_participantCount : '0' + LAN_participantCount }} / {{LAN_tournamentSize}}
                    </h2>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="horizontal-line mr-2"></div>
                        <div>
                            <span class="status-text">//participants inscrits</span>
                        </div>
                    </div>
                </div>
                <div class="online-status">
                    <h2 class="status-count text-left m-0">
                        {{participantCount > 0 ? participantCount : '0'+participantCount}} / {{tournamentSize}}
                    </h2>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="horizontal-line mr-2"></div>
                        <div >
                            <span class="status-text">//participants inscrits</span>
                        </div>
                    </div>
                </div>
            </div>


            <!--
            <div class="participants-status-container">
                <h3 class="participants-status m-0">
                    {{ participantCount}}  joueur{{participantCount> 1 ? 's' : ''}} inscrit{{participantCount> 1 ? 's' : ''}}
                </h3>
            </div>
            
            <div class="w-100" v-if="!isAuthenticated">
                <button class="participate-btn w-100" 
                    data-toggle="modal" data-target="#loginModal"
                    @click="participateAction()"
                >
                    PARTICIPER
                </button>
            </div>
            <div class="w-100" v-else>
                <button class="participate-btn w-100" data-toggle="modal" data-target="#lgame-participation-pop-up" @click="participateAction()"
                    v-if="!isParticipant"
                >
                    PARTICIPER
                </button>
                <button class="participate-btn px-5 w-100" data-toggle="modal" data-target="#lgame-participation-pop-up" 
                    @click="cancelParticipateAction()"
                    v-else
                >
                    Se desinscrire
                </button>
                <h6 class="font-weight-bold" v-if="isParticipant">( Vous êtes inscrit )</h6>
            </div>
        -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
    export default {
        name: 'ParticipateCard',
        data(){
            return {

                participantsSize: 0,
                TYPE_PLAYER: 1,
                TYPE_TEAM: 2,
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.$store.state.currentTournament.tournamentItem;
                },
                set(val) {
                    return val;
                }
            },
            LAN_isLoaded:{
                get() {
                    return !!this.$store.state.currentTournament.LAN_tournamentItem;
                },
                set(val) {
                    return val;
                }
            },
            startDate_comp:{
                get(){
                    if(this.isLoaded){
                        const date = this.$store.state.currentTournament.tournamentItem.start_date;
                        const time = this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment(date + ' ' + time, 'DD-MM-YYYY HH:mm').valueOf();
                    }
                    return null;
                }
                
            },
            isAuthenticated: {
                get() {
                    return this.$store.state.currentUser.isAuthenticated;
                }
            },
            registrationStatus: { // true:  open, false: closed
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.tournamentItem.settings.registration_status;
                    }
                }
            },
            registrationEnds: {
                get() {
                    if (this.isLoaded) {
                        moment.locale('fr');
                        const dateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment(dateTime, 'DD-MM-YYYY HH:mm').format('ddd Do, MMM  [à] hA');
                    }
                }
            },
            isPastTournament: {
                get() {
                    if (this.isLoaded) {
                        const dateTime = this.$store.state.currentTournament.tournamentItem.end_date + ' ' + this.$store.state.currentTournament.tournamentItem.end_time;
                        console.log(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                        return moment().isAfter(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            checkInStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        const checkInStartsIn = this.$store.state.currentTournament.tournamentItem.settings.check_in_starts;
                        const checkinStartTime = moment(startDateTime, 'DD-MM-YYYY HH:mm').subtract(checkInStartsIn, 'minutes').toDate();
                        return moment().isBefore(moment(startDateTime, 'DD-MM-YYYY HH:mm')) && moment().isAfter(moment(checkinStartTime));
                    }
                }
            },
            tournamentStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment().isSameOrAfter(moment(startDateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            participantType: {
                get() {
                    if (this.isLoaded) {
                        const size = this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
                        return size > 1 && size !== 0 ? this.TYPE_TEAM : this.TYPE_PLAYER;
                    }
                }
            },
            tournamentSize: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.tournamentItem.settings.size;
                    }

                }
            },
            participantCount: {
                get() {
                    if (this.isLoaded) {
                        return this.participantType === this.TYPE_PLAYER ?
                            this.$store.state.currentTournament.tournamentItem.participants_players.length :
                            this.$store.state.currentTournament.tournamentItem.participants_teams.length;
                    }
                    return 0;
                }
            },
            isParticipant: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.playerIsParticipant;
                        else
                            return this.$store.state.currentTournament.playerTeamIsParticipant;
                    }
                    return false;
                }
            },
            isParticipantCheckedIn: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.playerIsCheckedIn;
                        else
                            return this.$store.state.currentTournament.playerTeamIsCheckedIn;
                    }
                }
            },
            isLAN_participant:{
                get() {
                    if (this.LAN_isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.LAN_playerIsParticipant;
                        else
                            return this.$store.state.currentTournament.LAN_playerTeamIsParticipant;
                    }
                    return false;
                }
            },
            LAN_participantCount:{
                get() {
                    if (this.LAN_isLoaded) {
                        return this.participantType === this.TYPE_PLAYER ?
                            this.$store.state.currentTournament.LAN_tournamentItem.participants_players.length :
                            this.$store.state.currentTournament.LAN_tournamentItem.participants_teams.length;
                    }
                    return 0;
                },
                set(val) {
                    return val;
                }
            },
            LAN_tournamentSize:{
                get(){
                    if (this.LAN_isLoaded) {
                        return this.$store.state.currentTournament.LAN_tournamentItem.settings.size 
                            ? this.$store.state.currentTournament.LAN_tournamentItem.settings.size
                            :'-' ;
                    }
                },
                set(val){
                    return val;
                }
            },
            hasCustomFields: {
                get() {
                    if (this.isLoaded) {
                        const customFields = this.$store.state.currentTournament.tournamentItem.settings.custom_fields;
                        return customFields !== null && customFields.length > 0;
                    }
                }
            },
            isLoadingAction: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.isLoadingAction;
                    }
                }
            },
            teamParticipatedId: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentTournament.teamParticipatedId;
                    }
                }
            },
            isUserAuth: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentUser.isAuthenticated;
                    }
                }
            },
            registationStatus:{
                get(){
                    if(this.isLoaded){
                        return this.$store.state.currentTournament.tournamentItem.settings.registration_status;
                    }
                },
                set(val) {
                    return val;
                }
            },
            LAN_registrationStatus:{
                get(){
                    if (this.LAN_isLoaded) {
                        return this.$store.state.currentTournament.LAN_tournamentItem.settings.registration_status;
                    }
                },
                set(val){
                    return val;
                }
            }
    },
    /* eslint-disable */
    watch: {
        isUserAuth(newVal, oldVal) {
            if (oldVal === false && newVal === true && !this.isParticipant) {
                this.$store.dispatch('currentTournament/checkParticipation');
                this.$store.dispatch('currentTournament/LAN_checkParticipation');
            }
        },
        registationStatus(newVal, oldVal){
            return newVal;
        },
        LAN_registrationStatus(newVal, oldVal){
            return newVal;
        }
    },
         methods: {
            participateAction(isLAN) {
                // participate current auth user
                if (this.isAuthenticated) {
                    this.$store.dispatch('currentTournament/triggerLoading', true);
                    if (this.participantType === this.TYPE_TEAM) {
                        this.$store.dispatch('currentTournament/getCurrentPlayerTeams');
                        this.$emit('init-pop-up', {
                            type: 'teams',
                            title: "Choisir une équipe",
                            message: "Choisir une équipe parmis vous equipes pour participer a cette tournoi:",
                            withOptions: false,
                            popUpDispatch: 'currentTournament/setParticipantTeam',
                        });
                    }
                    if (this.participantType === this.TYPE_PLAYER) {
                        if (this.hasCustomFields) {
                            this.$emit('init-pop-up', {
                                type: 'custom',
                                withOptions: true,
                                title: "Formulaire d'inscription",
                                message: 'Merci de remplire les champs suivants pour participer au tournoi',
                                options: {
                                    mainAction: 'Participer',
                                    secondAction: 'Annuler'
                                },
                                popUpDispatch: isLAN ? 'currentTournament/LAN_setParticipant' : 'currentTournament/setParticipant',
                            });
                        } else {
                            if(isLAN){
                                this.$store.dispatch('currentTournament/LAN_setParticipant')
                                    .then(() => {
                                        this.$store.dispatch('currentTournament/LAN_triggerLoading', false);
                                    });
                            }else{
                                this.$store.dispatch('currentTournament/setParticipant')
                                    .then(() => {
                                        this.$store.dispatch('currentTournament/triggerLoading', false);
                                    });
                            }
                            
                        }
                    }
                } else {
                    this.$root.$emit('show-login', 'check_participation');
                }

            },
            cancelParticipateAction(isLan) {
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    console.log('cancelling player participation')
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: isLan ? 'currentTournament/LAN_cancelParticipation' : 'currentTournament/cancelParticipation',
                    });
                    /*this.$store.dispatch('currentTournament/cancelParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: 'currentTournament/cancelTeamParticipation'
                    });
                    console.log('cancelling team participation')
                    /*this.$store.dispatch('currentTournament/cancelTeamParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }

            },
            checkPlayerIn() {
                this.$store.dispatch('currentTournament/checkPlayerIn');
            },
            checkParticipantIn() {
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_TEAM) {
                    this.$store.dispatch('currentTournament/checkTeamIn', {
                        teamId: this.teamParticipatedId,
                        checkinValue: true,
                    }).then(() => {
                        this.$store.dispatch('currentTournament/triggerLoading', false);
                    });
                }
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$store.dispatch('currentTournament/checkPlayerIn', {
                        checkinValue: true,
                    })
                        .then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                }
            },
            cancelCheckIn() {
                //only team case done
                this.$store.dispatch('currentTournament/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: 'currentTournament/checkPlayerIn',
                        dispatchData: {
                            checkinValue: false,
                        }
                    })
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: 'currentTournament/checkTeamIn',
                        dispatchData: {
                            teamId: this.teamParticipatedId,
                            checkinValue: false,
                        }
                    });
                }
            },
            viewBracket() {
                this.$emit('view-bracket');
            },
        
        },
        mounted(){
            

        }
    }
</script>

<style scoped>
.participate-card-container{
    background-color: transparent;
    width: 100%;
}
.card-title{
    font-family: 'chaneyultra_extended', sans-serif;
    font-size: 18pt;
}
.card-title-container{
    width:100%;
    border: 2px solid white;
    height: 58px;

}
.btn-container{
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    height: 58px;
}
.btn-container:hover{
    cursor: pointer;
    background-color: #058ff8ab;
}
.btn-container:first-child{
    border-right:unset;
}
.btn-participate{
    font-family: 'chaneyultra_extended', sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    color: white;
    border: none;
    outline: none;
    background-color: transparent;
}
.participants-status{
    font-family: 'Gemunu Libre Bold';
}
.participate-btn{
    border:unset;
    background: transparent;
    outline: none;
    color: white;
    background-size: 100% 100%;
    height: 42px;
    font-family: 'Gemunu Libre Bold';
    font-size: 20pt;
}
.participate-btn:hover{
    transform: scale(1.01);
    transition: scale .1s linear;
}
.horizontal-line {
    width: 100px;
    height: 8px;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 46%, rgba(255, 255, 255, 1) 100%);
}
.status-count{
    font-family: 'chaneyultra_extended', sans-serif;
}
.status-text{
    font-family: 'chaneyultra_extended', sans-serif;
    text-transform:uppercase;
    font-size: 8pt;
    line-height: 100%;
}
@media screen and (max-width: 600px) {

    .card-title{
        font-size: 12pt;
    }
    .card-title-container{
        width: unset !important;
        border: unset !important;
        height: unset !important;
    }
    .participate-container{
        width: unset !important;
    }
    .registration-status{
        display:none !important;
    }
    .status-count{
        font-size: 12pt;
    }
    .horizontal-line{
        width: 40px;
        height: 5px;
    }
    .status-text{
        font-size: 6pt;
    }
    .btns-participate-wrapper{
        width: 95% !important;
    }

    .btn-container{
        border: 2px solid white !important;
        height: 46px;
    }
    .btn-participate{
        font-size: 10pt;
    }
    
    .number-item{
        font-size: 40pt;
    }
    .unit-item{
        font-size: 16pt;
    }
    .participate-btn{
        height: 38px;
        font-size: 14pt;
    }
}
</style>