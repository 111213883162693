<template>
    <div class="bracket-container  w-100 h-100">
        <EliminationBracket v-if="tournmaentLoaded && bracketStructure"></EliminationBracket>
        <div class="d-flex justify-content-center align-items-center w-100 h-100 my-5 mx-auto" v-else>
            <h5 class="font-weight-bold av-soon-text">Bracket sera bientôt disponible !</h5>
        </div>
    </div>
</template>

<script>
    import EliminationBracket from './EliminationBracket.vue';
    export default {
        name: 'BracketViewer',
        components:{
            EliminationBracket,
        },
        computed:{
            bracketStructure:{
                get(){
                    if(this.$store.state.currentTournament.tournamentItem){
                        return !!this.$store.state.currentTournament.tournamentItem.bracket.structure;
                    }
                    return false;
                }
            },
            tournmaentLoaded:{
                get(){
                    return !!this.$store.state.currentTournament.tournamentItem;
                }
            }
        }
    
    }
</script>
<style scoped>
.av-soon-text{
    color: rgba(255, 255, 255, 0.847);
    font-family: 'chaneyultra_extended', sans-serif;
    text-transform: uppercase;
    font-size: 18pt;
    letter-spacing: 1px;
}
</style>