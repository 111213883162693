<template>
    <div class="lgame-participation-pop-up-container-wrapper modal fade" id="lgame-participation-pop-up"
        data-backdrop="static" @keyup.esc="closeModal" tabindex="-1" role="dialog"
        aria-labelledby="lgameParticipationPopUpLabel" aria-hidden="true">
        <div class="lgame-participation-pop-up-container modal-dialog modal-dialog-centered p-md-2 mx-auto"
            role="document">
            <button ref="participateBtnClose" data-dismiss="modal" hidden @click="closeModal"></button>
            <div class="lgame-participation-pop-up-content-wrapper modal-content"
                :style="{backgroundColor: colors[popUpType]}">
                <div class="lgame-participation-pop-up-content p-3 p-md-4">
                    <div class="lgame-participation-pop-up-header mb-2">
                        <h4 class="pop-up-title text-left" :style="{color: colors[popUpType]}">{{popUpTitle}}</h4>
                    </div>
                    <div class="lgame-participation-pop-up-dynamic-content  my-2 px-2">
                        <slot name="dynamic">
                            <div v-if="popUpType === 'warning'">
                                <p class="lgame-participation-pop-up-message-body text-left p-0">{{messageBody}}</p>
                            </div>
                            <div class="my-2" v-if="popUpType === 'custom'">
                                <p class="lgame-participation-pop-up-message-body text-left">{{messageBody}}</p>
                                <div class="custom-fields-container">
                                    <div class="d-flex flex-column justify-content-start align-items-start mb-2 w-100"
                                        v-for="field in customFields" :key="field">
                                        <div>
                                            <p class="custom-field-label  m-0">{{field}}: </p>
                                        </div>
                                        <div class="custom-field-input-container  text-left w-100">
                                            <input type="text" class="custom-field-input px-2" maxlength="36"
                                                v-model="customFieldsValue[field]" required
                                                @focusin="fieldFocusIn(field)"
                                            />
                                        </div>
                                        <span class="span-error " v-if="customFields_emptyInputs[field]">Champs
                                            requis</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="popUpType === 'teams'">
                                <p class="lgame-participation-pop-up-message-body text-justify">{{messageBody}}</p>
                                <div class="player-teams-container py-1 pr-2 w-100">
                                    <div class="teams-loading d-flex justify-content-center align-items-center h-100"
                                        v-if="fetchingTeams">Loading...</div>
                                    <div v-else>
                                        <div v-if="currentPlayerTeams && !showCustomFields">
                                            <div class="player-team-item-container mb-2 w-100"
                                                v-for="team in currentPlayerTeams" :key="team.id">
                                                <div
                                                    class="player-team-item d-flex justify-content-between align-items-center w-100">
                                                    <div
                                                        class="d-flex justify-content-start align-items-center m-0 h-100">
                                                        <img alt="" class="team-item-avatar mr-4" width="56px"
                                                            height="100%" :src="teamAvatarDir+team.avatar" />
                                                        <p class="team-item-name m-0">{{team.team_name}}</p>
                                                    </div>
                                                    <div class="d-flex justify-content-start align-items-center h-100">
                                                        <div class="text-participate-by">
                                                            <p class="m-0 text-muted mr-2">Participer avec cette équipe
                                                            </p>
                                                        </div>
                                                        <button class="team-item-btn-participate"
                                                            @click="teamParticipate(team.id)"
                                                            :style="{backgroundColor: colors[popUpType]}">
                                                            <img alt="" class="img-arrow-participate"
                                                                :src="arrowParticipate" width="50%" height="50%">
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-fields-container" v-if="showCustomFields">
                                            <div class="d-flex flex-column justify-content-start align-items-start mb-2 w-100"
                                                v-for="field in customFields" :key="field">
                                                <div>
                                                    <p class="custom-field-label text-left m-0 ">{{field}}: </p>
                                                </div>
                                                <div class="custom-field-input-container text-left  w-100">
                                                    <input type="text" class="custom-field-input px-2" maxlength="36"
                                                        v-model="customFieldsValue[field]" required
                                                        @focusin="fieldFocusIn(field)" />
                                                </div>
                                                <span class="span-error" v-if="customFields_emptyInputs[field]">Champs
                                                    requis</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                    <div class="lgame-participation-pop-up-footer d-flex flex-column align-items-end mt-2">
                        <div class="horizontal-line my-2" :style="{backgroundColor: colors[popUpType]}"></div>
                        <div v-if="isWithOptions" class="d-flex justify-content-end align-items-center">
                            <button class="pop-up-button-cancel px-2 px-md-4" @click="abortAction">
                                {{actionsOptions.secondAction}}
                            </button>
                            <button class="pop-up-button px-3 px-md-4" @click="acceptAction"
                                :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                                @mouseover="buttonHover = true" @mouseleave="buttonHover=false">
                                {{actionsOptions.mainAction}}
                            </button>
                        </div>
                        <div class="d-flex justify-content-end align-items-center" v-else>
                            <button class="pop-up-button px-3 px-md-4" @click="acceptAction"
                                :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                                @mouseover="buttonHover = true" @mouseleave="buttonHover=false"
                                v-if="popUpType === 'custom'">
                                Participer
                            </button>
                            <button class="pop-up-button px-2 px-md-4" @click="abortAction" data-toggle="modal"
                                data-target="#lgame-participation-pop-up"
                                :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                                @mouseover="buttonHover = true" @mouseleave="buttonHover=false" v-else>
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import imageArrow from "@/assets/arrow_next.svg"
export default {
    name: "LgameParticipatePopUp",
    props: {
        popUpType: {
            type: String,
            default: 'warning'
        },
        title: {
            type: String,
            default: ''
        },
        popUpMessageBody: {
            type: String,
            default: '',
        },
        popUpDispatch: {
            type: String,
            default: '',
        },
        dispatchData: {
            type: Object,
            default() {
                return null;
            }
        }
        ,
        withOptions: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default() {
                return {
                    mainAction: 'Oui',
                    secondAction: 'Non',
                }
            }
        }
    },
    data() {
        return {
            definedTypes: ['warning', 'custom', 'teams'],
            colors: {
                custom: '#1890ff',
                warning: '#FFCC00',
                teams: '#fc0036',
            },
            buttonHover: false,
            customFieldsValue: {},
            arrowParticipate: imageArrow,
            teamParticipationDone: false,
            showCustomFields: false,
            popUpTitle: this.title,
            messageBody: this.popUpMessageBody,
            isWithOptions: this.withOptions,
            actionsOptions: this.options,
            teamId: null,
            teamAvatarDir: process.env.MIX_STORAGE_PATH + '/' + process.env.MIX_TEAMS_AVATARS_DIR,
            customFields_emptyInputs: {},
        }
    },
    watch:{
        withOptions(newVal, oldVal){
            this.isWithOptions = newVal;
        },
        options(newVal, oldVal){
            this.actionsOptions = {...newVal};
        },
        title(newVal, oldVal){
            this.popUpTitle = newVal;
        },
        popUpMessageBody(newVal, oldVal){
            this.messageBody = newVal;
        }
    },
    computed: {
        hasCustomFields: {
            get() {
                if(this.tournamentLoaded){
                    const customFields = this.$store.state.currentTournament.tournamentItem.settings.custom_fields;
                    return customFields !== null && customFields.length > 0;
                }
                return false;
            }
        },
        customFields: {
            get() {
                if(this.tournamentLoaded){
                    return this.$store.state.currentTournament.tournamentItem.settings.custom_fields ?
                    this.$store.state.currentTournament.tournamentItem.settings.custom_fields.split(',') : null;
                }
                return null;
            }
        },
        currentPlayerTeams: {
            get() {
                if(this.tournamentLoaded){
                    return this.$store.state.currentTournament.playerTeams;
                }
               return null;
            }
        },
        fetchingTeams: {
            get() {
                if(this.tournamentLoaded){
                    return this.$store.state.currentTournament.fetchingTeams;
                }
                return null;
            }
        },
        tournamentLoaded:{
            get() {
                return !!this.$store.state.currentTournament.tournamentItem;
            }
        }
    },
    methods: {
        executeAction() {
            this.$emit('executeAction');
            this.resetVars();
        },
        acceptAction() {
            console.log('action accepted: proceed');
            if (this.showCustomFields && this.popUpType === 'teams') {
                this.$emit('acceptAction', {
                    hasCustomFields: true,
                    customFieldsValues: this.customFieldsValue,
                    teamId: this.teamId
                });
                this.$refs.participateBtnClose.click();
            }
            else if (!this.showCustomFields && this.popUpType === 'teams') {
                this.$emit('acceptAction', {
                    hasCustomFields: false,
                    teamId: this.teamId
                });
                this.$refs.participateBtnClose.click();
            }
            else if (this.hasCustomFields && this.popUpType === 'custom') {
                if (this.verifyCustomFields()) {
                    this.$emit('acceptAction', {
                        hasCustomFields: true,
                        customFieldsValues: this.customFieldsValue,
                    });
                    this.$refs.participateBtnClose.click();
                }
            }
            else {
                this.$emit('acceptAction');
                this.$refs.participateBtnClose.click();
            }

        },
        abortAction() {
            console.log('action aborted: cancel');
            this.$emit('abortAction');
            this.resetVars();
            this.$refs.participateBtnClose.click();
        },
        closeModal() {
            this.$refs.participateBtnClose.click();
            console.log('modal clicked');
            this.resetVars();
            this.$emit('modal-closed');
        },
        resetVars() {
            this.teamId = null;
            this.showCustomFields = false;
            this.customFieldsValue = {};
        },
        getRGBA(color, opacity = 0.8) {
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
        },
        teamParticipate(teamId) {
            if (this.hasCustomFields) {
                this.showCustomFields = true;
                this.popUpTitle = "informations supplémentaire";
                this.messageBody = "Veuillez fournir les informations suivantes pour compléter l'inscription de votre équipe";
                this.isWithOptions = true;
                this.actionsOptions = {
                    mainAction: 'Participer',
                    secondAction: 'Annuler'
                };
                this.teamId = teamId;
            } else {
                this.$store.dispatch('currentTournament/setParticipantTeam', {
                    teamId: teamId
                }).then(() => {
                    this.$store.dispatch('currentTournament/triggerLoading', false);
                    this.$refs.participateBtnClose.click();
                });
            }
        },
        verifyCustomFields() {
            for (let field in this.customFieldsValue) {
                this.customFields_emptyInputs[field] = this.customFieldsValue[field] === '';
                console.log(this.customFields_emptyInputs[field])
            }
            this.$forceUpdate();
            for (let field in this.customFields_emptyInputs) {
                if (this.customFields_emptyInputs[field] === true) return false;
            }
            return true;
        },
        fieldFocusIn(field) {
            this.customFields_emptyInputs[field] = false;
            this.$forceUpdate();
        }
    },
    created() {
        console.log('participant pop up created ....');
        if (this.customFields && this.customFields.length > 0) {
            this.customFields.forEach(field => {
                this.customFields_emptyInputs[field] = false;
                this.customFieldsValue[field] = '';
            });
        }
    }
}
</script>

<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(3px);
}

.modal-content {
    border-radius: unset;
}

.modal-dialog {
    /*width: 45vw !important;
    min-width: 580px !important;
    max-width: none;*/
    max-width: 600px;
}

.lgame-participation-pop-up-container-wrapper {}

.lgame-participation-pop-up-content-wrapper {
    padding: 12px 12px 4px 4px;
    /*padding: 6px 6px 2px 2px;*/
    clip-path: polygon(0 8px, 55% 8px, calc(55% + 8px) 0, calc(100% - 14px) 0, 100% 14px, 100% 46%, calc(100% - 8px) calc(46% + 8px), calc(100% - 8px) 100%, 12px 100%, 0 calc(100% - 12px));
    /*clip-path: polygon(0 8px, 55% 8px, calc(55% + 8px) 0,  calc(100% - 14px) 0, 100% 14px, 100% 46%, calc(100% - 8px) calc(46% + 8px), calc(100% - 8px) 100%, 12px 100%, 0 calc(100% - 12px));
/*polygon(0 5px, 70% 5px, calc(70% + 5px) 0,  calc(100% - 10px) 0, 100% 10px, 100% 40%, calc(100% - 5px) calc(40% + 8px), calc(100% - 5px) 100%, 10px 100%, 0 calc(100% - 10px))*/
}

.lgame-participation-pop-up-content {
    color: #C4C4C4;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background-color: #010D11;
    clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
    width: 100%;
    height: 90%;
}

.pop-up-title {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 2rem;
}

.pop-up-title::first-letter {
    text-transform: capitalize;
}

.horizontal-line {
    width: 100%;
    height: 0.5pt;
}

.pop-up-button {
    color: white;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12pt;
    border: unset;
    border-radius: 0;
    outline: none;
    border: none;
    width: fit-content;
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
}

.pop-up-button:hover {
    color: white;
    transform: scale(1.01);
}

.pop-up-button:active {
    color: white;
    transform: scale(1.01);
}

.pop-up-button:focus {
    outline: none;
}

.pop-up-button-cancel {
    border: none;
    outline: none;
    color: #C4C4C4;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12pt;
}

.pop-up-button-cancel:hover {
    color: white;
    transform: scale(1.01);
}

.custom-field-label {
    color: white;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
}

.custom-field-input-container {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
}

.custom-field-input {
    color: #AAAAAA;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    border: unset;
    border-radius: 0;
    height: 30px;
    background-color: #00171F;
    border: unset;
    outline: none;
    width: 60%;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}

.custom-field-input:active,
.custom-field-input:focus {
    outline: none;
    border: none;
    background-color: rgba(255, 0, 0, 0.6);
}

.player-teams-container {
    height: 360px;
    overflow-y: auto;

}

.player-team-item-container {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.8));
}

.player-team-item-container:hover {
    transform: translateX(5px);
    cursor: pointer;
}

.text-participate-by {
    display: none;
}

.player-team-item:hover .text-participate-by {
    display: block;
}

.player-team-item {
    height: 56px;
    background-color: #00171F;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.team-item-name {
    color: white;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}

.team-item-btn-participate {
    background-color: #FF0000;
    width: 56px;
    height: 100%;
    outline: none;
    border: none;
}

.team-item-btn-participate:focus,
.team-item-btn-participate:active {
    outline: none;
    border: none;
}

.team-item-btn-participate:hover {
    background-color: rgba(255, 0, 0, 0.8) !important;
}

.span-error {
    color: rgba(255, 0, 0, 0.8);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

@media only screen and (max-width: 768px) {
    .modal-dialog {
        width: 90vw !important;
        max-width: 600px;
        min-width: unset !important;
        height: calc(calc(var(--vh, 1vh) * 100) - 60px);
    }

    .modal-content {}

    .lgame-participation-pop-up-content {
        clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 8px 100%, 0 calc(100% - 8px));
    }

    .pop-up-button,
    .pop-up-button-cancel {
        letter-spacing: normal;
        font-size: 10pt;
        clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
    }

    .player-teams-container {
        height: 260px;
    }

    .player-team-item:hover .text-participate-by {
        display: none;
    }

    .custom-field-input {
        width: 100%;
        max-width: 280px;
    }
}
</style>
