<template >
    <div class="lgame-login-container">
        <div class="modal fade px-2" ref="loginModal" id="loginModal" tabindex="-1"
            aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="static" role="dialog" @keyup.esc="hide">
            <div class="modal-dialog modal-dialog-centered mx-auto">
                <button ref="modalBtnClose" data-dismiss="modal" hidden></button>
                <div class="modal-content text-left" v-if="showLogin">
                    <div class="modal-header  d-flex justify-content-between align-items-center p-2">
                        <h5 class="modal-title m-0" id="loginModalLabel">SE CONNECTER</h5>
                        <div class="modal-header-closer m-0" @click="hide">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                <g transform="translate(-9145 -8766)">
                                    <g transform="translate(9170 8767) rotate(90)" opacity="0.9">
                                        <g>
                                            <g>
                                                <path d="M16.727,24H0V3.2L2.89,0H22V17.022Z" fill="none" stroke="white"
                                                    stroke-width="2" />
                                            </g>
                                        </g>
                                    </g>
                                    <path
                                        d="M8.228-42.254,5.355-45.906,2.522-42.254H.949l3.6-4.537L1.168-51.1h1.56l2.682,3.4,2.654-3.4H9.555L6.176-46.829,9.8-42.254Z"
                                        transform="translate(9152.625 8824.677)" fill="white" stroke="white"
                                        stroke-width="1" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="modal-body mx-md-4 my-md-2">
                        <div v-if="resendTriggered">
                            <div class="my-2">
                                <h3 class="text-danger text-left">Vérification du compte Lgame.gg</h3>
                                <p class="text-white-50 m-0 text-left">
                                    Un mail vous sera envoyé pour vérifier votre compte.
                                </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-end mt-4">
                                <button class="close-button px-5 py-md-1" @click="hide">Fermer</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" v-if="isLoading">
                            <lgame-loader-comp />
                        </div>
                        <div v-if="!isLoading && !resendTriggered">
                            <form class="px-auto">
                                <div class="form-group text-left">
                                    <label class="m-0 text-left" for="emailLogin">Email</label><br>
                                    <input type="email" class="email-input lgame-input" id="emailLogin"
                                        v-model="user.email" @focusin="focusIn" @keyup.enter="logIn" />
                                    <span class="lgame-input-error" v-if="emptyInputs.email"><br>champ requis</span>
                                </div>
                                <div class="form-group">
                                    <div
                                        class="lgame-forgot-pass-label d-flex justify-content-between align-items-center">
                                        <label class="m-0 text-left" for="passwordLogin">Mot de passe</label>
                                        <a type="button" @click="triggerInitPass" class="pwdForgot m-0"
                                            data-toggle="modal" data-target="#passResetModal">
                                            Mot de passe oublié ?
                                        </a>
                                    </div>
                                    <div
                                        class="lgame-password-input-container d-flex justify-content-start align-items-center">
                                        <input :type="isPassword ? 'password' : 'text'"
                                            class="password-input lgame-input" id="passwordLogin"
                                            v-model="user.password" @focusin="focusIn" @keyup.enter="logIn" />
                                        <div class="lgame-password-trigger-container d-flex justify-content-center align-items-center"
                                            @click="triggerPass">
                                            <img alt=""
                                                :src="isPassword ? passwordIcon.invisible : passwordIcon.visible"
                                                width="45%" height="45%" />
                                        </div>
                                    </div>
                                    <span class="lgame-input-error text-left" v-if="emptyInputs.password">champ requis</span>
                                </div>
                                <div class="rememberme-contaner">
                                    <lgame-check-box @checked="rememberMe = !rememberMe" :selected="rememberMe">
                                        <label class="form-check-label">
                                            Rester connecté
                                        </label>
                                    </lgame-check-box>
                                </div>
                                <div class="lgame-invalid-credentials-error d-flex justify-content-start align-items-center p-1"
                                    v-if="invalidCredentials">
                                    <img alt="" :src="errorTriangleIcon" class="mx-2" />
                                    <div>
                                        <h5 class="lgame-error-title m-0">Erreur:</h5>
                                        <p class="lgame-error-message m-0">E-mail ou mot de passe incorrect.</p>
                                    </div>
                                </div>
                            </form>
                            <div class="email-verification-error p-3 mt-2 mt-md-4 w-100" v-if="emailNotVerified">
                                <div v-if="!resendTriggered">
                                    <h3 class="text-danger">Votre adresse e-mail n'est pas vérifiée</h3>
                                    <p class="text-white-50 m-0">
                                        Veuillez vérifier votre adresse e-mail en cliquant sur le lien dans l'e-mail que
                                        nous avons envoyé.
                                    </p>
                                    <p class="text-white-50 m-0">
                                        Si vous n'avez pas reçu d'email <a class="span-connexion"
                                            @click.prevent="resendVerificationEmail">cliquez ici</a>
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-end mt-2 mt-md-5">
                                <div class="have-account p-0">Pas de compte ? <span @click="triggerRegister"
                                        class="span-connexion">S'inscrire</span></div>
                                <button class="submit-button px-4 py-md-1 ml-4" @click="logIn">
                                    <span v-if="isLoading">Connexion ...</span>
                                    <span v-else>Connexion</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer p-0">
                    </div>
                </div>
                <div class="modal-content" v-if="showInitPass">
                    <div class="modal-header d-flex justify-content-between align-items-center p-2">
                        <h5 class="modal-title m-0" id="passResetModalLabel">Réinitialisez votre mot de passe</h5>
                        <div class="modal-header-closer m-0" @click="hide">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                <g transform="translate(-9145 -8766)">
                                    <g transform="translate(9170 8767) rotate(90)" opacity="0.9">
                                        <g>
                                            <g>
                                                <path d="M16.727,24H0V3.2L2.89,0H22V17.022Z" fill="none" stroke="white"
                                                    stroke-width="2" />
                                            </g>
                                        </g>
                                    </g>
                                    <path
                                        d="M8.228-42.254,5.355-45.906,2.522-42.254H.949l3.6-4.537L1.168-51.1h1.56l2.682,3.4,2.654-3.4H9.555L6.176-46.829,9.8-42.254Z"
                                        transform="translate(9152.625 8824.677)" fill="white" stroke="white"
                                        stroke-width="1" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="modal-body mx-md-4 my-md-2">
                        <form class="px-auto" v-if="!reset_sent">
                            <div class="form-group text-left">
                                <label class="lgame-label m-0 text-left" for="emailReset">Email</label><br>
                                <input type="email" class="email-input lgame-input" id="emailReset"
                                    v-model="email_reset" @keyup.enter="pwdForgotten" @focusin="focusIn">
                                <span class="lgame-input-error" v-if="emptyInputs.email_reset"><br>champ requis</span>
                            </div>
                            <div class="form-group m-0 text-left">
                                <a type="button" @click="triggerLogin" class="pwdForgot text-left">Retour</a>
                            </div>
                        </form>
                        <div v-else>
                            <p class="m-0">Un email sera envoyé à: <span
                                    class="highlight-email"><strong>{{email_reset}}</strong></span>
                            </p>
                            <p class="text-justify">
                                Veuillez suivre les instructions contenues dans l'e-mail pour réinitialiser votre mot de
                                passe.
                            </p>
                        </div>
                        <div class="d-flex align-items-center justify-content-end mt-2 mt-md-4">
                            <button class="submit-button px-4 py-md-1 ml-4" @click="pwdForgotten" v-if="!reset_sent">
                                <span v-if="isLoading">Envoi...</span>
                                <span v-else>Envoyer</span>
                            </button>
                            <button class="close-button px-4 py-md-1 ml-2" @click="pwdForgotten"
                                v-else>Renvoyer</button>
                            <button class="submit-button px-4 py-md-1 ml-2 ml-md-4" @click="hide"
                                v-if="reset_sent">Fermer</button>
                        </div>
                    </div>
                    <div class="modal-footer p-0">
                    </div>
                </div>

                <div class="lgame-register-container modal-content" v-if="showRegister">
                    <div class="modal-header d-flex justify-content-between align-items-center p-2"
                        :class="{'userRegistered': userRegistered}">
                        <h5 class="modal-title" id="registerModalLabel" v-if="!userRegistered">Inscription</h5>
                        <h5 class="modal-title" id="registerModalLabel" v-else>Inscription effectuée</h5>
                        <div class="modal-header-closer m-0" @click="hide">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                <g transform="translate(-9145 -8766)">
                                    <g transform="translate(9170 8767) rotate(90)" opacity="0.9">
                                        <g>
                                            <g>
                                                <path d="M16.727,24H0V3.2L2.89,0H22V17.022Z" fill="none" stroke="white"
                                                    stroke-width="2" />
                                            </g>
                                        </g>
                                    </g>
                                    <path
                                        d="M8.228-42.254,5.355-45.906,2.522-42.254H.949l3.6-4.537L1.168-51.1h1.56l2.682,3.4,2.654-3.4H9.555L6.176-46.829,9.8-42.254Z"
                                        transform="translate(9152.625 8824.677)" fill="white" stroke="white"
                                        stroke-width="1" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div class="modal-body mx-md-4 my-md-2" v-if="!userRegistered">
                        <form class="px-auto">
                            <div class="form-group text-left">
                                <label class="lgame-label m-0 text-left" for="pseudoName">Nom de joueur</label><br>
                                <input type="text" class="pseudo-name-input lgame-input" id="pseudoName"
                                    @focusin="focusIn" v-model="registerInputs.player_name" @keyup.enter="register">
                                <span class="lgame-input-error" v-if="emptyInputs.player_name"><br>champ requis</span>
                                <span class="lgame-input-error" v-if="alreadyTakenErrors.name"><br>ce nom de joueur a
                                    déjà été pris</span>
                            </div>
                            <div class="form-group text-left">
                                <label class="lgame-label m-0 text-left" for="email">Email</label><br>
                                <input type="email" class="email-input lgame-input" id="email" @focusin="focusIn"
                                    v-model="registerInputs.email" @keyup.enter="register">
                                <span class="lgame-input-error" v-if="emptyInputs.email"><br>champ requis</span>
                                <span class="lgame-input-error" v-if="alreadyTakenErrors.email"><br>Cet email a déjà été
                                    pris</span>
                            </div>
                            <div class="form-group text-left">
                                <label class="lgame-label m-0 text-left" for="password">Mot de passe</label>
                                <div
                                    class="lgame-password-input-container d-flex justify-content-start align-items-center">
                                    <input :type="isPassword_reg ? 'password' : 'text'"
                                        class="password-input lgame-input" id="password"
                                        v-model="registerInputs.password" @focusin="focusIn" @keyup.enter="register" />
                                    <div class="lgame-password-trigger-container d-flex justify-content-center align-items-center"
                                        @click="isPassword_reg = !isPassword_reg">
                                        <img alt=""
                                            :src="isPassword_reg ? passwordIcon.invisible : passwordIcon.visible"
                                            width="45%" height="45%" />
                                    </div>
                                </div>
                                <span class="lgame-input-error" v-if="emptyInputs.password">champ requis</span>
                                <span class="lgame-input-error" v-if="passLengthError && !emptyInputs.password">Le mot
                                    de passe doit comporter au moins 8 caractères.</span>
                            </div>
                            <div class="form-group text-left">
                                <label class="lgame-label m-0 text-left" for="confirmPassword">Confirmer le mot de passe</label>
                                <div
                                    class="lgame-password-input-container d-flex justify-content-start align-items-center">
                                    <input :type="isPassword_confirm ? 'password' : 'text'"
                                        class="password-input lgame-input" id="confirmPassword"
                                        v-model="registerInputs.password_confirmed" @focusin="focusIn"
                                        @keyup.enter="register" />
                                    <div class="lgame-password-trigger-container d-flex justify-content-center align-items-center"
                                        @click="isPassword_confirm = !isPassword_confirm">
                                        <img alt=""
                                            :src="isPassword_confirm ? passwordIcon.invisible : passwordIcon.visible"
                                            width="45%" height="45%" />
                                    </div>
                                </div>
                                <span class="lgame-input-error" v-if="emptyInputs.password_confirmed">champ
                                    requis</span>
                                <span class="lgame-input-error" v-if="!passConfirmed">Veuillez confirmer votre mot de
                                    passe</span>
                            </div>

                            <div class="form-check p-0">
                                <lgame-check-box @checked.exact="acceptConditions = !acceptConditions"
                                    :selected="acceptConditions">
                                    <label class="lgame-label form-check-label text-left">
                                        J’accepte les <a class="span-condition" @click.exact="readConditions">conditions
                                            d’utilisation de <strong>Lgame.gg</strong></a>
                                    </label>
                                </lgame-check-box>
                            </div>
                            <div class="form-check p-0 mt-2">
                                <lgame-check-box @checked="acceptNewsLetter = !acceptNewsLetter"
                                    :selected="acceptNewsLetter">
                                    <label class="form-check-label">J’accepte de recevoir la newsletter de
                                        <strong>Lgame.gg</strong></label>
                                </lgame-check-box>
                            </div>
                        </form>
                        <div class="lgame-invalid-credentials-error d-flex justify-content-start align-items-center p-1"
                            v-if="errors.length > 0">
                            <img alt="" :src="errorTriangleIcon" class="mx-2" />
                            <div>
                                <p class="lgame-error-message m-0 text-left" v-for="(error, index) in errors" :key="index">{{error}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end mt-4">
                            <div class="have-account p-0">Vous avez déjà un compte ?
                                <span @click="triggerLogin" class="span-connexion">Connexion</span>
                            </div>
                            <button class="submit-button px-4 py-md-1 ml-4" @click="register"
                                v-if="isLoading">Inscription...</button>
                            <button class="submit-button px-4 py-md-1 ml-4" @click="register"
                                v-else>Inscription</button>
                        </div>
                    </div>
                    <div class="modal-body mx-md-4 my-md-2" v-else>
                        <!--v-else-->
                        <div class="account-created-container p-2">
                            <h2 class=" text-white-50 text-left">Votre compte a été créé avec succès</h2>
                            <p class="text-muted text-left">Bienvenue à bord! un mail de vérification vous a été envoyé, veuillez
                                vérifier votre courrier électronique.</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-end mt-4">
                            <button class="close-button px-5 py-md-1" @click="hide">Fermer</button>
                        </div>
                    </div>
                    <div class="modal-footer p-0" :class="{'userRegistered': userRegistered}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import errorTriangle from '@/assets/error-triangle.svg';
import eyeSlash from '@/assets/eye-slash.svg';
import eye from '@/assets/eye.svg';
import LgameCheckBox from "./LgameCheckBox";
import LgameLoaderComp from "./LgameLoaderComp";
import axios from 'axios';
export default {
    name: "LgameLogin",
    components:{
        LgameLoaderComp,
        LgameCheckBox,
    },
    props:{
        login:{
            type: Boolean,
            default: true,
        },
        actionAfter:{
             type: String,
             default: null,
        }
    },
     data() {
        return {
            showLogin: true,
            showRegister: false,
            showInitPass: false,
            user: {
                email: "",
                password: "",
            },
            registerInputs:{
                email: '',
                password: '',
                password_confirmed: '',
                player_name: '',
                accept_newsletter: this.acceptNewsLetter,
            },
            emptyInputs: {
                email: false,
                password: false,
                password_confirmed: false,
                player_name: false,
                email_reset: false,
            },
            email_reset: '',
            reset_sent: false,
            isLoading: false,
            invalidCredentials: false,
            errorTriangleIcon: errorTriangle,
            isPassword: true,
            isPassword_reg: true,
            isPassword_confirm: true,
            passwordIcon:{
                visible: eye,
                invisible: eyeSlash
            },
            passConfirmed: true,
            acceptNewsLetter: false,
            acceptConditions: false,
             errors: [],
            actions:{
                check_participation: 'currentTournament/checkParticipation',
            },
            rememberMe: false,
            alreadyTakenErrors: {},
            passLengthError: false,
            userRegistered: false,
            emailNotVerified: false,
             resendTriggered:false,
        };
    },
    methods:{

           logIn(){
             if(!this.verifyInputs()){
                this.isLoading = true;
                this.$store.dispatch('currentUser/loginUser', {
                    ...this.user,
                    rememberMe: this.rememberMe
                })
                    .then((response) => {
                    console.log('%c============================================', 'color: red')
                    console.log('from login pop: ');
                    console.log(response);
                    console.log('%c============================================', 'color: red');
                    this.isLoading = false;
                    if(!response.success){
                        console.log(response.success)
                        if(response.errorType === "invalidCredentials"){
                            this.invalidCredentials = true;
                        }
                        if(response.errorType === "emailVerificationError"){
                            this.emailNotVerified = true;
                            console.log('we got errors errors ======= EMAIL NOT VERIFIED ==')
                            console.log(response)
                            console.log('we got errors errors ======= EMAIL NOT VERIFIED ==')
                        }
                    }else{
                        if(this.actionAfter){
                            this.$store.dispatch(this.actions[this.actionAfter]);
                            this.$store.dispatch('currentTournament/LAN_checkParticipation');
                        }
                        this.hide();
                    }
                });
            }
        },
        register(){
            if(!this.verifyInputs()){
                this.isLoading = true;
                this.$store.dispatch('currentUser/registerUser', this.registerInputs)
                    .then((response) => {
                        this.isLoading = false;
                        if(!response.success){
                            this.alreadyTakenErrors = response.error;
                            this.invalidCredentials = true;
                            // show a flash message account created please verify email
                        }else{
                            //this.hide();
                           // this.$router.push({name: 'emailVerification'});
                            this.userRegistered = true;
                        }
                    });
            }
        },
        pwdForgotten(){
            if(!this.verifyInputs()){
                this.isLoading = true;
                this.$store.dispatch('currentUser/requestResetPassword', {
                    email: this.email_reset
                })
                .then((response) => {
                    this.isLoading = false;
                    response.success ? this.email_sent = true : this.email_sent = false;
                });
                this.isLoading = false;
                this.reset_sent = true;
            }
        },
        resendVerificationEmail(){
            this.resendTriggered = true;
            if(!this.verifyInputs()){
                axios.post('user/email/verify/resend', {
                    email: this.user.email,
                })
                    .then(response => {
                        console.log(response);
                    }).catch(error => {
                    console.log(error.response);
                })
            }
        },
        hide(){
            this.$refs.modalBtnClose.click();
            this.isLoading = false;
            this.emptyInputs = {
                email: false,
                password: false,
                password_confirmed: false,
                player_name: false,
                email_reset: false,
            };
            this.isPassword = true;
            this.isPassword_reg = true;
            this.isPassword_confirm = true;
            this.passConfirmed = true;
            this.userRegistered = false
            this.emailNotVerified = false;
            this.resendTriggered = false;
            this.initCredentials();
            this.triggerLogin();
            this.$emit('hide-login');
        },
        initCredentials(){
            console.log('init credentials')
            this.user.email = "";
            this.user.password = "";
            this.invalidCredentials = false;
            this.errors = [];
            this.email_sent = false;
        },
        verifyInputs(){
            if(this.showLogin){
                this.emptyInputs.email = this.user.email === '';
                this.emptyInputs.password = this.user.password === '';
                return this.emptyInputs.email || this.emptyInputs.password;
            }
            if(this.showRegister){
                this.emptyInputs.email = this.registerInputs.email === '';
                this.emptyInputs.password = this.registerInputs.password === '';
                this.emptyInputs.password_confirmed = this.registerInputs.password_confirmed === '';
                this.emptyInputs.player_name = this.registerInputs.player_name === '';
                this.passConfirmed = this.registerInputs.password === this.registerInputs.password_confirmed;
                this.passLengthError = this.registerInputs.password.length < 8;
                if(this.acceptConditions === false) this.errors.push("Accepter les conditions d'utilisation pour continuer.");
                return  this.emptyInputs.email || this.emptyInputs.password || this.emptyInputs.password_confirmed || this.emptyInputs.player_name || !this.passConfirmed || !this.acceptConditions || this.passLengthError;
            }
           if(this.showInitPass){
                this.emptyInputs.email_reset = this.email_reset === '';
                return this.emptyInputs.email_reset;
           }
        },
        focusIn(){
            this.emptyInputs.email = false;
            this.emptyInputs.password = false;
            this.invalidCredentials = false;
            this.emptyInputs.password_confirmed = false;
            this.emptyInputs.player_name = false;
            this.emptyInputs.email_reset = false;
            this.passConfirmed = true;
            this.errors = [];
            this.email_sent = false;
            this.passLengthError = false;
        },
        triggerPass(){
            this.isPassword = !this.isPassword;
        },
        triggerLogin(){
            this.showRegister = false;
            this.showLogin = true;
            this.showInitPass = false;
        },
        triggerRegister(){
            this.showRegister = true;
            this.showLogin = false;
            this.showInitPass = false;
        },
        triggerInitPass(){
            this.showRegister = false;
            this.showLogin = false;
            this.showInitPass = true;
        },
        readConditions(){
            console.log()
            window.open('https://lgame.gg/general-conditions', '_blank');
        }
    },
    created(){
        console.log('==================== login created ==================')
        this.login === true ? this.triggerLogin() : this.triggerRegister();
    },
    activated(){
        this.login === true ? this.triggerLogin() : this.triggerRegister();
    }
}
</script>


<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(3px);
}

.modal-dialog {
    max-width: 600px;
}

.modal-content {
    height: 100%;
    color: white;
    border: unset;
    border-radius: 0;
    background-color: #00171F;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%);
}

.modal-header {
    color: white;
    border-bottom: unset;
    height: 100%;
    border: unset;
    border-radius: 0;
    background-color: #ff0000;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%);
}

.modal-header-closer {
    cursor: pointer;
    width: fit-content;
}

.modal-header-closer:hover {
    transform: scale(1.01);
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
}

.modal-footer {
    height: 2px;
    background-color: red;
    border-top: unset;
    border: unset;
    border-radius: 0;
}

.userRegistered {
    background-color: #3C9907 !important;
}

.lgame-input {
    font-family: 'Roboto', 'sans-serif';
    padding: 6px;
    height: 36px;
    border: unset;
    border-radius: 0;
    width: 80%;
    color: white;
    background-color: #010D11;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);

}

.lgame-input:focus {
    outline: none;
    background-color: rgba(255, 0, 0, 0.8);
}

.form-check-label {
    font-size: 12px;
}

.have-account {
    color: white;
    font-size: 12px;
}

span a {
    font-weight: bold;
    color: red;
}

.submit-button {
    color: white;
    outline: none;
    border: none;
    background-color: #ff0000;
    clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.submit-button:hover {
    background-color: rgba(255, 0, 0, 0.8);
    transform: scale(1.01);
}

.close-button {
    color: #EAEAEA;
    outline: none;
    border: none;
    background-color: rgba(1, 13, 17, 1);
    clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%, 0 6px);
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.close-button:hover {
    background-color: rgba(1, 13, 17, 0.8);
    transform: scale(1.01);
}

.lgame-input-error {
    color: #FF0000;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 10pt;
}

.pwdForgot {
    color: red;
    font-weight: bold;
}

.pwdForgot:hover {
    transform: scale(1.01);
}

input[type=password] {
    -webkit-text-security: square;
}

.lgame-invalid-credentials-error {
    color: red;
    font-family: 'Roboto', sans-serif;
    background-color: rgba(1, 13, 17, 0.4);
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 4px);
    width: 80%;
}

.lgame-forgot-pass-label {
    width: 80%;
}

.lgame-error-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 10pt;
    color: #FF0000;
}

.lgame-error-message {
    font-family: 'Roboto', sans-serif;
    font-size: 10pt;
    color: #FF0000;
}

.lgame-password-input-container {
    height: 36px;
    width: 80%;
    background-color: #010D11;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.lgame-password-trigger-container {
    height: 36px;
    width: 46px;
    background-color: #FF0000;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.lgame-password-trigger-container:hover {
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.8);
}

.password-input {
    width: calc(100% - 46px);
    clip-path: unset;
}

.password-input:focus .lgame-password-input-container {
    outline: none;
    background-color: rgba(255, 0, 0, 0.8);
}

.span-connexion {
    color: #FF0000;
    font-weight: bold;
}

.span-connexion:hover {
    text-decoration: underline #ff0000;
    cursor: pointer;
}

.lgame-label:after {
    content: ' *';
    color: #FF0000;
}

.span-condition {
    color: #FF0000;
}

.span-condition:hover {
    cursor: pointer;
}

.highlight-email {
    color: #FF0000;
}

.account-created-container h1 {
    font-family: "Roboto", sans-serif;
}

.text-success {
    color: #0E8100 !important;
}

.email-verification-error {
    background-color: rgba(1, 13, 17, 0.4);
    clip-path: polygon(4px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 4px);
}

.text-danger {
    color: #FF0000 !important;
}

@media only screen and (max-width: 768px) {
    .lgame-input {
        width: 90%;
        height: 32px;
    }

    .lgame-password-input-container {
        width: 90%;
        height: 32px;
    }

    .lgame-password-trigger-container {
        height: 32px;
        width: 38px;
    }

    .password-input {
        width: calc(100% - 38px);
    }

    .lgame-forgot-pass-label {
        width: 90%;
    }

    .lgame-invalid-credentials-error {
        width: 100%;
    }
}
</style>
